import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(private firestore: AngularFirestore) { }

  async checkIntervieweePreviousRound(collectionName: string, candiateId) {
    return await this.firestore.collection(collectionName, ref => ref.where("candidate_id", "==", candiateId).orderBy("OtherInfo.created_at", "desc")).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getInterviewLevel(collectionName: string) {
    return await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getInterviewers(collectionName: string, field, value) {
    return await this.firestore.collection(collectionName, ref => ref.where(field, 'in', value)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getInterviewFeedbackParameter(collectionName: string) {
    let feedbackParams = await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return feedbackParams[0];
  }

  async addInterview(collectionName: any, data: any) {
    return await this.firestore.collection(collectionName).add(data);
  }

  async getLists(collectionName: any) {
    return await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getInterviewslists(collectionName: any) {
    return await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getDetails(collectionName: string, id: string) {
    return await this.firestore.collection(collectionName).doc(id).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

  }

  async getAssignedInterviewers(collectionName: string, id: string) {
    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), "in", id)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getCandidates(collectionName: string, status) {
    return this.firestore.collection(collectionName, ref => ref.where("OtherInfo.candidate_status", "==", status)).snapshotChanges();
  }

  async updateData(collectionName: string, data: any, id: string) {
    return await this.firestore.collection(collectionName).doc(id).update(data);

  }

  async deleteJobDetails(collectionName: string, id: string) {
    return await this.firestore.collection(collectionName).doc(id).delete();
  }

  async getFeedback(collectionName, id) {
    return await this.firestore.collection(collectionName, ref => ref.where("interview_id", "==", id)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getUserDetails(collectionName, id) {
    return await this.firestore.collection(collectionName, ref => ref.where("user_id", "==", id)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getData(collectionName: string, field: string, value: string, field1: string, value1: string) {
    return await this.firestore.collection(collectionName, ref => ref.where(field, "==", value).where(field1, "==", value1)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

  }

  async getDetailsByField(collectionName: string, field: string, value: string) {
    return await this.firestore.collection(collectionName, ref => ref.where(field, "==", value)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getTestsList(collectionName, field, value) {
    return await this.firestore.collection(collectionName, ref => ref.where(field, "array-contains-any", value)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async addAssignTests(collectionName, data) {
    return await this.firestore.collection(collectionName).add(data);
  }
  async updateAssignTests(collectionName, id, data) {
    return await this.firestore.collection(collectionName).doc(id).update(data);
  }

  async getDetailsByDocumentId(collectionName, id) {
    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), "==", id)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }


}
